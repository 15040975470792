import React, {FC} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";
import RenewalPriceCard from "../../components/renewal-price-card/RenewalPriceCard";
import HelpCard from "../../components/help-card/HelpCard";
import {selectedRenewalQuoteChanged, shouldProtectNoClaimsBonusChanged} from "../../redux/RenewalSlice";
import {
  selectAreBothRenewalOptionsAvailable,
  selectSelectedQuoteType,
  selectShouldGetRebrokedQuoteWithNCBProtection,
  selectSortedRenewalQuotes
} from "../../redux/RenewalSelectors";
import {loadRebrokedQuote} from "../../redux/thunks/LoadRebrokedQuote";
import "./RenewalPriceCards.css";
import {RenewalQuoteType} from "../../models/RenewalQuoteType";

const RenewalPriceCards: FC = () => {
  const dispatch = useAppDispatch();
  const sortedQuotes = useAppSelector(selectSortedRenewalQuotes);
  const areBothRenewalOptionsAvailable = useAppSelector(selectAreBothRenewalOptionsAvailable);
  const selectedQuoteType = useAppSelector(selectSelectedQuoteType);
  const shouldGetRebrokedQuoteWithNCBProtection = useAppSelector(selectShouldGetRebrokedQuoteWithNCBProtection);

  const onSelection = (quoteType: RenewalQuoteType): void => {
    dispatch(selectedRenewalQuoteChanged(quoteType));
    if (quoteType === "REBROKE" && shouldGetRebrokedQuoteWithNCBProtection) {
      dispatch(shouldProtectNoClaimsBonusChanged(true));
      dispatch(loadRebrokedQuote(true));
    }
  };

  return (
    <div className="renewal-price-cards" id="renewal-price-cards">
      <div className="renewal-price-cards__cards" id="renewal-price-cards__cards">
        {sortedQuotes.map((quote, index) => (
          <RenewalPriceCard
            key={quote.quoteType}
            insurer={quote.insurerName}
            totalUnfinancedAmount={quote.totalUnfinancedAmountPayable}
            displayRadioButton={areBothRenewalOptionsAvailable}
            selected={selectedQuoteType === quote.quoteType}
            onSelection={onSelection}
            isBestPrice={index === 0}
            quoteType={quote.quoteType}
          />
        ))}
        {!areBothRenewalOptionsAvailable && <HelpCard/>}
      </div>
    </div>
  );
};

export default RenewalPriceCards;
