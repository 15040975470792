import {FC} from "react";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./CompareCoverHeader.css";
import {useAppSelector} from "../../../../../redux/Hooks";
import {
  selectActiveRenewalNotice,
  selectExistingPolicyForRenewal,
  selectPolicyDocuments,
  selectTotalUnfinancedRenewalAmount,
  selectRenewalInsurerName,
} from "../../redux/RenewalSelectors";
import {DocumentType} from "shared/dist/generated/graphql/resolvers-types";
import {scrollToElement} from "../../../../../utils/navigation/ScrollToHelpers";
import {useLocation, useNavigate} from "react-router-dom";
import HeadlinePrice from "../headline-price/HeadlinePrice";
import {NorthEast} from "@mui/icons-material";
import {displayLoanValue} from "../../../../../utils/service/string/DisplayLoanValue";

const CompareCoverHeader: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const renewalNotice = useAppSelector(selectActiveRenewalNotice);
  const totalUnfinancedAmount = useAppSelector(selectTotalUnfinancedRenewalAmount);
  const renewalPolicy = useAppSelector(selectExistingPolicyForRenewal);
  const selectedQuoteDocuments = useAppSelector(selectPolicyDocuments);
  const insurerName = useAppSelector(selectRenewalInsurerName);

  const oldIpid = renewalPolicy?.documentation?.active ? renewalPolicy?.documentation.active.find(doc => doc.type === DocumentType.InsuranceProductInformation) : undefined;

  return (
    <div className="compare-cover-header" id="compare-cover" data-testid="compare-cover-header">
      <div className="compare-cover-header__info">
        <h5>{lookupI18nString("portal.renewals.summary.compareCover.title")}</h5>
        <p>{lookupI18nString("portal.renewals.summary.compareCover.description")}</p>
      </div>

      <div className="compare-cover-header__card compare-cover-header__card--current">
        <h6 data-testid="compare-cover-header__insurer">
          {lookupI18nString({
            id: "portal.renewals.summary.compareCover.thisYears.title",
            templateVariables: {insurer: insurerName ?? "-----"}
          })}
        </h6>

        <HeadlinePrice
          price={totalUnfinancedAmount}
          testId="compare-cover-header__current-price"/>

        <Button
          className={"compare-cover-header__view-payment-options-button"}
          variant="secondary"
          expanded
          small
          onClick={() => scrollToElement("renewal-price-summary")}
          data-testid="compare-cover-header__view-payment-options-button"
        >
          {lookupI18nString("portal.renewals.summary.compareCover.thisYears.button")}
        </Button>

        <a
          className="compare-cover-header__view-new-ipid-button"
          data-testid="compare-cover-header__view-new-ipid-button"
          onClick={() => {
            if (selectedQuoteDocuments) open(selectedQuoteDocuments?.insuranceProductInformation);
          }}
        >
          <p>{lookupI18nString("portal.renewals.summary.compareCover.thisYears.viewIpid")}</p>
          <NorthEast/>
        </a>
      </div>

      <div className="compare-cover-header__card compare-cover-header__card--previous">
        <h6>{lookupI18nString("portal.renewals.summary.compareCover.lastYears.title")}</h6>

        <HeadlinePrice
          price={renewalNotice?.lastYearsPremium}
          testId="compare-cover-header__last-years-price"
        />

        <div className="compare-cover-header__card__breakdown" data-testid="compare-cover-header__annualised-price">
          <span>{lookupI18nString("portal.renewals.summary.compareCover.lastYears.previousAmount")}</span>
          <span
            className="compare-cover-header__card__breakdown--price">{displayLoanValue(renewalNotice?.annualised)}</span>
        </div>

        <Button
          className="compare-cover-header__view-breakdown-button"
          variant="ghost"
          expanded
          small
          onClick={() => {
            navigate(location.pathname);
            navigate("breakdown");
          }}
          data-testid="compare-cover-header__view-breakdown-button"
        >
          {lookupI18nString("portal.renewals.summary.compareCover.lastYears.button")}
        </Button>

        <a
          className="compare-cover-header__view-old-ipid-button"
          onClick={() => {
            if (oldIpid) open(oldIpid.url);
          }}
        >
          <p>{lookupI18nString("portal.renewals.summary.compareCover.thisYears.viewIpid")}</p>
          <NorthEast/>
        </a>
      </div>
    </div>
  );
};

export default CompareCoverHeader;
