import {FC, MouseEvent} from "react";
import "./RenewalPriceCard.css";
import HeadlinePrice from "../headline-price/HeadlinePrice";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {Price} from "shared/dist/generated/graphql/resolvers-types";
import {scrollToElement} from "../../../../../utils/navigation/ScrollToHelpers";
import {RadioButtonChecked, RadioButtonUnchecked} from "@mui/icons-material";
import {RenewalQuoteType} from "../../models/RenewalQuoteType";
import classNames from "classnames";

export interface RenewalPriceCardProps {
  quoteType: RenewalQuoteType;
  insurer?: string;
  totalUnfinancedAmount?: Price;
  displayRadioButton: boolean;
  selected: boolean;
  onSelection: (quoteType: RenewalQuoteType) => void;
  isBestPrice: boolean;
}

const RenewalPriceCard: FC<RenewalPriceCardProps> = (
  {
    quoteType,
    insurer,
    totalUnfinancedAmount,
    displayRadioButton,
    selected,
    isBestPrice,
    onSelection
  }: RenewalPriceCardProps
) => {
  const rootId = `renewal-price-card-${isBestPrice ? "primary" : "secondary"}`;
  const isRenewal = quoteType === "RENEWAL";

  const onClick = (event: MouseEvent): void => {
    event.preventDefault();

    if (displayRadioButton) onSelection(quoteType);
  };

  return (
    <div
      className={classNames("renewal-price-card", {"renewal-price-card__selected": displayRadioButton && selected}, {"renewal-price-card__unselected": displayRadioButton && !selected})}
      data-testid={rootId}
      data-testname={`${quoteType.toLowerCase()}-quote`}
      onClick={onClick}
    >
      <div className="renewal-price-card__header">
        <div
          className="renewal-price-card__header__radio-button"
          data-testid={`${rootId}__header__radio-button`}
          hidden={!displayRadioButton}
        >
          <input
            type="radio"
            name={"foo"}
            id={"renewal-price-card__header__radio-button__input"}
            data-testid={`${rootId}__header__radio-button__input`}
            checked={selected}
            readOnly={true}
          />
          <label htmlFor={"renewal-price-card__header__radio-button__input"}>
            <div
              className="renewal-price-card__header__radio-button__icon"
              data-testid={`${rootId}__header__radio-button__icon`}
            >
              <RadioButtonUnchecked/>
              <RadioButtonChecked/>
            </div>
          </label>
        </div>
        <span
          className="renewal-price-card__header__title"
          data-testid={`${rootId}__header__title`}
        >
          {lookupI18nString(
            !displayRadioButton && isRenewal
              ? "portal.renewals.summary.priceCard.renewalOnlyPriceTitle"
              : isBestPrice
                ? "portal.renewals.summary.priceCard.bestPriceTitle"
                : "portal.renewals.summary.priceCard.nextBestPriceTitle"
          )}
        </span>
        {isRenewal && <span
          className="renewal-price-card__header__tag"
          data-testid={`${rootId}__header__tag`}
        >
          {lookupI18nString("portal.renewals.summary.priceCard.currentInsurerTag")}
        </span>}
      </div>

      <div className="renewal-price-card__body">
        <p
          className={"renewal-price-card__body__title"}
          data-testid={`${rootId}__title-with-insurer`}
        >
          {
            lookupI18nString({
              id: isRenewal
                ? "portal.renewals.summary.priceCard.renewalInsurer"
                : "portal.renewals.summary.priceCard.rebrokeInsurer",
              templateVariables: {insurerName: insurer ?? "---"}
            })
          }
        </p>
        <HeadlinePrice
          price={totalUnfinancedAmount}
          testId={`${rootId}__total-amount`}
        />
        <p className={"renewal-price-card__body__price-description"}>
          {lookupI18nString("portal.renewals.summary.priceCard.iptText")}
        </p>

        <Button
          variant="secondary"
          expanded
          small
          disabled={!selected}
          onClick={() => scrollToElement("compare-cover")}
        >
          {lookupI18nString("portal.renewals.summary.priceCard.button")}
        </Button>
      </div>
    </div>
  );
};

export default RenewalPriceCard;
